<script>
import { i18n } from '@/main'
export default {
  model: {
    prop: 'currentData',
    event: 'change'
  },
  props:{
    currentData: {
      type: [Number, String, Array],
      default: () => {}
    },
    columns: {
      type: Array,
      default:()=>([])
    },
    getContainer:{
      type: String,
      default:'#app'
    },
    valueKey:{
      type: String,
      default: 'code'
    },
    labelKey:{
      type: String,
      default:'name'
    },
    placeholder: {
      type: String,
      default: i18n.t('请选择')
    },
    hyphen: {
      type: String,
      default: '/'
    },
    required:{
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showPicker: false,
      columnObj: {}
    }
  },
  computed: {
    showValue() {
      if (this.currentData) {
        if (Object.prototype.toString.call(this.currentData) === '[object Array]') {
          if (this.currentData.length > 0) {
            return this.getShowValue()
          } else {
            return this.placeholder
          }
        } else {
          return this.getShowValue()
        }
      } else {
        return this.placeholder
      }
    },
    isShowRed() {
      const { required,showValue,placeholder } = this
      return required && showValue === placeholder
    }
  },
  watch: {
    columns: {
      deep: true,
      handler(nVal) {
        this.columnObj = {}
        this.arrayToObj(nVal, this.columnObj)
      }
    }
  },
  created() {
    this.arrayToObj(this.columns, this.columnObj)
  },
  methods:{
    closePopup() {
      this.showPicker = false
    },
    confirm(val) {
      this.$emit('change', val[this.valueKey], val)
      this.closePopup()
    },
    arrayToObj(arr, obj) {
      const { valueKey, labelKey } = this
      arr.forEach(item => {
        obj[item[valueKey]] = { [labelKey]: item[labelKey], children: {} }
        if (item.children) {
          this.arrayToObj(item.children, obj[item[valueKey]].children )
        }
      })
    },
    getShowValue() {
      const { valueKey, labelKey } = this
      const val = this.currentData
      const obj = this.columnObj
      if (Object.prototype.toString.call(val) === '[object Array]') {
        let children = {}
        let str = ''
        for (let i in val) {
          const item = val[i]
          if (i === 0) {
            str = str + obj[item] ? obj[item][labelKey] : '' + this.hyphen
            children = obj[item] ? obj[item].children : {}
          } else if (i === val.length - 1) {
            str = str + children[item] ? children[item][labelKey] : ''
          } else {
            str = str + children[item] ? children[item][labelKey] : '' + this.hyphen
            children = children[item] ? children[item].children : {}
          }
        }
        return str
      } else {
        try {
          return obj[val][labelKey] || ''
        } catch (error) {
          console.log(error)
        }
      }
    },
  }
}
</script>
<template>
  <div>
    <van-cell v-bind="$attrs" :required="required" is-link @click.stop="()=>{
     if(isEdit) showPicker = true
    }">
    <span :class="{red: isShowRed}" class="value">{{ showValue }}</span>
    </van-cell>
    <van-popup v-model="showPicker" :get-container="getContainer"  round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        :value-key="labelKey"
        @cancel="closePopup"
        @confirm="confirm"
      >
    </van-picker>
</van-popup>
  </div>
</template>
<style lang="less" scoped>
.red{
  color: #ee0a24 !important;
}
.value{
  color: @black;
}
</style>
